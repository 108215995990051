







import Vue from 'vue';

export default Vue.extend({
    name: 'LineLoader',
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        active(this: any) {
            return this.$store.getters.isLineLoading;
        },
    },
});

