














































import Vue from 'vue';

export default Vue.extend({
    name: 'loader',
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        active(this: any) {
            return this.$store.getters.isLoading;
        },
    },
    watch: {
        active(this: any, newValue: boolean) {
            if (newValue) {
                this.loading = true;
                setTimeout((() => { this.loading = false; }), 10);
            }
        },
    },
});
