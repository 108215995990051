

















































import Vue from 'vue';

export default Vue.extend({
    name: 'PhotoFilters',
    data(this: any) {
        return {
            search: '',
            currentAlbum: '',
            dataInf: '',
            dataSup: '',
        };
    },
    computed: {
        album(this: any) {
            return this.$store.getters.albums.map((item) => ({
                id: item.id,
                title: `${item.externalId}: ${item.title}`,
            }));
        },
    },
    methods: {
        filter(this: any) {
            const filters: any = {};
            if (this.currentAlbum) filters.album = this.currentAlbum;
            if (this.search) filters.q = this.search;
            if (this.dataInf) filters.dataInf = this.dataInf;
            if (this.dataSup) filters.dataSup = this.dataSup;

            this.$emit('filter', filters);
        },
        selectAlbum(this: any, itemText, queryText) {
            return (itemText.title.toLowerCase().indexOf(queryText.toLowerCase()) > -1);
        },
    },
});

