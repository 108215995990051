import FondazioneApi from '@/services/backend';
import { SerieInt } from '@/api/series/types';

export const SERIE_GET = 'SERIE_GET';
export const SERIE_ADD = 'SERIE_ADD';
export const SERIE_UPDATE = 'SERIE_UPDATE';
export const SERIE_DELETE = 'SERIE_DELETE';

interface SerieState {
    serie: SerieInt[];
}

export interface actionsParams {
    commit: (action: string, payload: unknown) => unknown,
    state: SerieState,
    getters: any,
    dispatch: any,
}

export default {
    state: {
        serie: [],
    },
    mutations: {
        [SERIE_GET](state: SerieState, serie: SerieInt[]): void {
            state.serie = serie;
        },
    },

    getters: {
        serie: (state: SerieState): SerieInt[] => state.serie,
    },

    actions: {
        async [SERIE_GET]({ commit }: actionsParams): Promise<void> {
            commit(SERIE_GET, []);
            const serie: SerieInt[] = await FondazioneApi.getSerie();
            commit(SERIE_GET, serie);
        },
        async [SERIE_ADD]({ commit }: actionsParams, serie: SerieInt): Promise<void> {
            await FondazioneApi.insertSerie(serie);
        },
        async [SERIE_UPDATE]({ commit }: actionsParams, serie: SerieInt): Promise<void> {
            await FondazioneApi.updateSerie(serie);
        },
        async [SERIE_DELETE]({ commit }: actionsParams, fondoId: string): Promise<void> {
            await FondazioneApi.deleteSerie(fondoId);
        },
    },
};
