import { getAnalytics, logEvent } from 'firebase/analytics';
import FondazioneApi from '@/services/backend';
import { PhotoInt, PhotoResult, QueryInt } from '@/api/photo/types';

export const PHOTO_GET = 'PHOTO_GET';
export const PHOTO_ADD = 'PHOTO_ADD';
export const PHOTO_UPDATE = 'PHOTO_UPDATE';
export const PHOTO_DELETE = 'PHOTO_DELETE';
export const PHOTO_GET_TOTAL_COUNT = 'PHOTO_GET_TOTAL_COUNT';

interface PhotoState {
    photos: PhotoInt[];
    totalCount: number;
}

export interface actionsParams {
    commit: (action: string, payload: unknown) => unknown,
    state: PhotoState,
    getters: any,
    dispatch: any,
}

export default {
    state: {
        photos: [],
        totalCount: 0,
    },
    mutations: {
        [PHOTO_GET](state: PhotoState, photos: PhotoInt[]): void {
            state.photos = photos;
        },
        [PHOTO_GET_TOTAL_COUNT](state: PhotoState, totalCount: number): void {
            state.totalCount = totalCount;
        },
    },

    getters: {
        photos: (state: PhotoState): PhotoInt[] => state.photos,
        photosTotalCount: (state: PhotoState): number => state.totalCount,
    },

    actions: {
        async [PHOTO_GET]({ commit }: actionsParams, options: QueryInt): Promise<void> {
            commit(PHOTO_GET, []);
            const photos: PhotoResult = await FondazioneApi.getPhotos(options);
            commit(PHOTO_GET, photos.items);
            commit(PHOTO_GET_TOTAL_COUNT, photos.totalCount);
            try {
                const analytics = getAnalytics();
                await logEvent(analytics, 'view_item_list', {
                    item_list_id: 'photos',
                    item_list_name: 'photos',
                    page: options?.page,
                    perPage: options?.itemsPerPage,
                    filer_title: options?.title,
                    filer_free_search: options?.q,
                    filer_serie: options?.album,
                    filer_dataInf: options?.dataInf,
                    filer_dataSup: options?.dataSup,
                    items: photos.items,
                });
                console.log('Sending done!');
            } catch (e) {
                console.log(e);
            }
        },
        async [PHOTO_ADD]({ commit }: actionsParams, photo: PhotoInt): Promise<void> {
            await FondazioneApi.insertPhoto(photo);
        },
        async [PHOTO_UPDATE]({ commit }: actionsParams, photo: PhotoInt): Promise<void> {
            await FondazioneApi.updatePhoto(photo);
        },
        async [PHOTO_DELETE]({ commit }: actionsParams, photoId: string): Promise<void> {
            await FondazioneApi.deletePhoto(photoId);
        },
    },
};
