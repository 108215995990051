import FondazioneApi from '@/services/backend';
import { FondiInt } from '@/api/fondi/types';

export const FONDI_GET = 'FONDI_GET';
export const FONDI_ADD = 'FONDI_ADD';
export const FONDI_UPDATE = 'FONDI_UPDATE';
export const FONDI_DELETE = 'FONDI_DELETE';

interface FondiState {
    fondi: FondiInt[];
}

export interface actionsParams {
    commit: (action: string, payload: unknown) => unknown,
    state: FondiState,
    getters: any,
    dispatch: any,
}

export default {
    state: {
        fondi: [],
    },
    mutations: {
        [FONDI_GET](state: FondiState, fondi: FondiInt[]): void {
            state.fondi = fondi;
        },
    },

    getters: {
        fondi: (state: FondiState): FondiInt[] => state.fondi,
    },

    actions: {
        async [FONDI_GET]({ commit }: actionsParams): Promise<void> {
            commit(FONDI_GET, []);
            const fondi: FondiInt[] = await FondazioneApi.getFondi();
            commit(FONDI_GET, fondi);
        },
        async [FONDI_ADD]({ commit }: actionsParams, fondo: FondiInt): Promise<void> {
            await FondazioneApi.insertFondi(fondo);
        },
        async [FONDI_UPDATE]({ commit }: actionsParams, fondo: FondiInt): Promise<void> {
            await FondazioneApi.updateFondi(fondo);
        },
        async [FONDI_DELETE]({ commit }: actionsParams, fondoId: string): Promise<void> {
            await FondazioneApi.deleteFondi(fondoId);
        },
    },
};
