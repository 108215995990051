














import Vue from 'vue';
import {
    USERS_DELETE, USERS_DISABLE, USERS_ENABLE, USERS_GET,
} from '@/store/modules/users';

export default Vue.extend({
    name: 'UserTable',
    data(this: any) {
        return {
            headers: [
                { text: 'Email', value: 'email' },
                { text: 'Nome e cognome', value: 'name' },
                { text: 'Telefono', value: 'phone' },
                { text: 'Affiliazione', value: 'affiliation' },
                { text: 'Data creazione ', value: 'creationAt' },
                { text: 'Data ultimo login ', value: 'lastLoginAt' },
                { text: 'Azioni ', value: 'actions' },
            ],
            search: '',
            loading: false,
        };
    },
    props: ['items'],
    methods: {
        setLoading(this: any, loading: boolean) {
            this.loading = loading;
        },
        async enableDisable(this: any, userId: string, enabled: boolean) {
            this.loading = true;
            await this.$store.dispatch(enabled ? USERS_DISABLE : USERS_ENABLE, userId);
            await this.$store.dispatch(USERS_GET);
            this.loading = false;
        },
        async deleteUser(this: any, userId: string) {
            this.loading = true;
            await this.$store.dispatch(USERS_DELETE, userId);
            await this.$store.dispatch(USERS_GET);
            this.loading = false;
        },
    },
});
