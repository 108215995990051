import Vue from 'vue';
import DatetimePicker from 'vuetify-datetime-picker';
import { initializeApp } from 'firebase/app';
import FirebaseConfig from '@/fireconfig';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
// import 'material-design-icons-iconfont/dist/material-design-icons.css';..

Vue.config.productionTip = false;
Vue.use(DatetimePicker);
initializeApp(FirebaseConfig);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
