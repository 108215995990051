






































import Vue from 'vue';
import { ASSET_SET_SIGNED_URL } from '@/store/modules/asset';

export default Vue.extend({
    name: 'FileViewer',
    data(this: any) {
        return {
            isOpen: false,
            loading: false,
            url: '',
        };
    },
    props: {
        fileNames: Array,
        paperId: String,
        showDelete: Boolean,
    },
    computed: {
        isImage(this: any) {
            return this.fileName;
        },
        icon(this: any) {
            if (this.fileName && this.fileName.indexOf('.pdf') > 0) {
                return 'mdi-file-pdf-box';
            }

            if (this.fileName && (this.fileName.indexOf('.jpg') || this.fileName.indexOf('.jpeg'))) {
                return 'mdi-file-jpg-box';
            }

            if (this.fileName && this.fileName.indexOf('.png')) {
                return 'mdi-file-png-box';
            }

            return 'mdi-file-document';
        },
    },
    methods: {
        async showPreview(this: any, fileName: string) {
            this.loading = true;
            const [url] = await this.$store.dispatch(ASSET_SET_SIGNED_URL, [fileName]);
            if (window) {
                window.open(url, '_blank');
            }
            this.loading = false;
        },
        async deleteFile(this: any, fileName: string) {
            this.$emit('delete', fileName);
        },
    },
});
