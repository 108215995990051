import { NavigationGuardNext, Route } from 'vue-router';
import { FIREBASE_IS_USER_AUTHENTICATED } from '@/store/modules/fireauth';
import Store from '../store';

export default (publicUrls: string[]) => async (to: Route, from: Route, next: NavigationGuardNext): Promise<any> => {
    await Store.dispatch(FIREBASE_IS_USER_AUTHENTICATED);

    if (Store.getters.isLoggedIn && ['/login'].includes(to.path)) {
        return next('/dashboard');
    }

    // if it is a public url just go next
    if (publicUrls.includes(to.path)) {
        return next();
    }

    // If the user is not authenticated let's block it
    if (!Store.getters.isLoggedIn) {
        return next('/');
    }

    return next();
};
