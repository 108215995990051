




















import { FIREBASE_SET_USER_INFO } from '@/store/modules/fireauth';

export default {
    name: 'Profile',
    data(this: any) {
        return {
            name: '',
            phone: '',
            affiliation: '',
            valid: '',
            rules: {
                required: (value: any) => !!value || 'Campo obbligatorio.',
                min: (min: number) => (v: any) => (v && v.length >= min) || `Mininimo ${min} caratteri`,
            },
            loading: false,
        };
    },
    mounted(this: any) {
        if (this.infos) {
            this.name = this.infos.name;
            this.phone = this.infos.phone;
            this.affiliation = this.infos.affiliation;
        }
    },
    computed: {
        infos(this: any) {
            return this.$store.getters.userInfo;
        },
    },
    methods: {
        async update(this: any) {
            this.loading = true;
            await this.$store.dispatch(FIREBASE_SET_USER_INFO, { name: this.name, phone: this.phone, affiliation: this.affiliation });
            this.loading = false;
        },
    },
};
