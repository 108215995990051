





























import Vue from 'vue';
import { PHOTO_GET, PHOTO_DELETE } from '@/store/modules/photo';
import Edit from './edit.vue';
import Detail from './detail.vue';
import Upload from '@/components/common/uploader/index.vue';
import { AlbumInt } from '@/api/album/types';
import Filters from './filters.vue';
import { ASSET_DELETE, ASSET_UPLOAD } from '@/store/modules/asset';
import { LUCIDI_GET } from '@/store/modules/lucidi';
import { LucidiInt } from '@/api/lucidi/types';

export default Vue.extend({
    name: 'PaperTable',
    components: {
        Edit,
        Filters,
        Detail,
        Upload,
    },
    data(this: any) {
        return {
            selectedLucidi: '',
            detailOpen: false,
            filters: '',

            search: '',
            loading: false,
            open: false,
            options: {},
        };
    },
    props: ['items', 'totalCount'],
    watch: {
        options: {
            handler(this: any) {
                this.getItems();
            },
            deep: true,
        },
    },
    computed: {
        isAdmin(this: any): boolean {
            return this.$store.getters.isAdmin;
        },
        headers(this: any) {
            const items: any = [
                {
                    text: 'Cat.', value: 'cat', sortable: false,
                },
                {
                    text: 'Identificativo elaborato', value: 'elaborato', sortable: false,
                },
                {
                    text: 'Unità archivistica', value: 'unita', sortable: true,
                },
                {
                    text: 'Titolo e descrizione dell\' elaborato', value: 'titolo', sortable: false,
                },
                {
                    text: 'Sezione d\' archivio', value: 'sezione',
                },
                {
                    text: 'Indicazioni di responsabilità', value: 'resp',
                },
                {
                    text: 'Data elaborato', value: 'data',
                },
                {
                    text: 'Tipo supporto', value: 'tipo',
                },
                {
                    text: 'Alt. max (cm)', value: 'altezza',
                },
                {
                    text: 'Lun. max (cm)', value: 'lunghezza',
                },
                {
                    text: 'Note', value: 'note',
                },
            ];

            if (this.isAdmin && 0) {
                items.push({
                    text: 'Azioni', value: 'actions', sortable: false, align: 'end', width: '10%',
                });
            }

            return items;
        },
    },
    methods: {
        async getItems(this: any) {
            const {
                page, itemsPerPage, sortBy, sortDesc,
            } = this.options as any;
            this.loading = true;
            await this.$store.dispatch(LUCIDI_GET, {
                page: (page - 1), itemsPerPage: parseInt(itemsPerPage || '10', 10), sortBy, sortDesc, ...this.filters,
            });
            this.loading = false;
        },
        add(this: any) {
            this.$refs.editor.open();
        },
        setLoading(this: any, loading: boolean) {
            this.loading = loading;
        },
        updateItem(this: any, item: AlbumInt) {
            this.$refs.editor.open(item);
        },
        async deleteItem(this: any, id: string) {
            this.loading = true;
            await this.$store.dispatch(PHOTO_DELETE, id);
            await this.$store.dispatch(PHOTO_GET);
            this.loading = false;
        },
        async filter(this: any, filters: any) {
            this.filters = filters;
            this.getItems();
        },
        async deleteFile(this: any, item: string) {
            this.$refs.upload.setLoading(true);
            await this.$store.dispatch(ASSET_DELETE, { asset: item, id: this.selectedLucidi.id, category: 'photo' });
            await this.$store.dispatch(PHOTO_GET);
            this.$refs.upload.setLoading(false);
            this.selectedLucidi.image = '';
        },
        async uploadFile(this: any, file: File) {
            this.$refs.upload.setLoading(true);
            await this.$store.dispatch(ASSET_UPLOAD, { file, id: this.selectedLucidi.id, category: 'lucidi' });
            await this.$store.dispatch(PHOTO_GET);
            this.$refs.upload.setLoading(false);
            this.selectedLucidi.image = `photo/${file.name}`;
        },
        openDetail(this: any, item: LucidiInt) {
            console.log('aaaa', item);
            this.selectedLucidi = item;
            this.$nextTick(() => {
                this.$refs.mydetail.open();
            });
        },
    },
});
