import Vue from 'vue';

// eslint-disable-next-line no-shadow
export enum GLOBAL_BUS_TYPES {
    NOTIFICATION = 'NOTIFICATION',
    REDIRECT = 'REDIRECT',
    LOGOUT = 'LOGOUT',
}

// eslint-disable-next-line no-shadow
export enum NOTIFICATION_TYPE {
    INFO,
    ERROR,
}

export interface INotification {
    type: NOTIFICATION_TYPE;
    message: string;
}

export interface IRedirect {
    path: string;
}

export default new Vue();
