







































import Vue from 'vue';
import { FIREBASE_LOGIN } from '@/store/modules/fireauth';
import LoggedInMenu from './components/loggedin.vue';
import LoggedOutMenu from './components/loggedout.vue';

export default Vue.extend({
    name: 'fondazioneHeader',
    data() {
        return {
            error: '',
            token: '',
            drawer: false,
        };
    },
    props: {
        msg: String,
    },
    components: {
        LoggedInMenu,
        LoggedOutMenu,
    },
    computed: {
        isAuthenticated(this: any): boolean {
            return this.$store.getters.isLoggedIn;
        },
        user(this: any): boolean {
            return this.$store.getters.user;
        },
    },
    methods: {
        login(this: any) {
            this.$store.dispatch(FIREBASE_LOGIN);
        },
    },
});
