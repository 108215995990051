





















import { LUCIDI_GET_CAT } from '@/store/modules/lucidi';

export default {
    name: 'LucidiMain',
    components: {
    },
    data(this: any) {
        return {
            categories: [],
            current: '0',
        };
    },
    computed: {
        currentCategory(this: any) {
            const current:any = this.categories.find((item: any) => item.id === this.current);
            return current;
        },
        mainId(this: any) {
            const current:any = this.categories.find((item: any) => item.id === this.current);
            if (current && current.name) {
                return current.id;
            }

            return '';
        },
        childs(this: any) {
            const current:any = this.categories.find((item: any) => item.id === this.current);
            if (current && current.childs) {
                return current.childs;
            }

            return '';
        },
    },
    watch: {
        '$route.params': {
            async handler(this: any) {
                this.categories = await this.$store.dispatch(LUCIDI_GET_CAT);
                this.current = this.$route.params.category;
            },
            immediate: true,
        },
    },

};
