



















import { PHOTO_GET } from '@/store/modules/photo';
import PhotoTable from './components/table.vue';
import { AlbumInt } from '@/api/album/types';
import { ALBUM_GET } from '@/store/modules/album';

export default {
    name: 'Photo',
    components: {
        PhotoTable,
    },
    data(this: any) {
        return {
        };
    },
    async mounted(this: any): Promise<void> {
        this.$refs.table.setLoading(true);
        await this.$store.dispatch(PHOTO_GET);
        await this.$store.dispatch(ALBUM_GET);
        this.$refs.table.setLoading(false);
    },
    computed: {
        isAdmin(this: any): boolean {
            return this.$store.getters.isAdmin;
        },
        photos(this:any): AlbumInt[] {
            return this.$store.getters.photos;
        },
        totalCount(this:any): number {
            return this.$store.getters.photosTotalCount;
        },
    },
};
