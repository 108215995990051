










import Vue from 'vue';
import FondazioneHeader from '@/components/common/header/index.vue';

export default Vue.extend({
    name: 'NotAuthorized',
    components: {
        FondazioneHeader,
    },
});
