


















































































import Vue from 'vue';
import { PHOTO_GET, PHOTO_ADD, PHOTO_UPDATE } from '@/store/modules/photo';
import { PhotoInt } from '@/api/photo/types';
import { AlbumInt } from '@/api/album/types';

export default Vue.extend({
    name: 'EditPaper',
    data(this: any) {
        return {
            id: '',
            idPhoto: '',
            album: '',
            title: '',
            description: '',
            authorCopyright: '',
            dataSup: '',
            dataInf: '',
            termini: '',
            luogo: '',
            pdn: '',
            color: '',
            supportoNoteTecniche: '',
            dimension: '',
            notes: '',
            valid: '',
            loading: false,
            currentAlbum: '',
            rules: {
                required: (value: any) => !!value || 'Campo obbligatorio.',
                min: (min: number) => (v: any) => (v && v.length >= min) || `Mininimo ${min} caratteri`,
            },
            isOpen: false,
            editing: false,
        };
    },
    computed: {
        albums(this: any) {
            return this.$store.getters.albums.map((item) => ({
                id: item.id,
                title: `${item.externalId}: ${item.title.substr(0, 50)}...`,
            }));
        },
    },
    methods: {
        async update(this: any) {
            try {
                this.loading = true;
                const updateObject = {
                    id: this.id,
                    idPhoto: this.idPhoto,
                    album: this.currentAlbum,
                    title: this.title,
                    description: this.description,
                    authorCopyright: this.authorCopyright,
                    dataSup: this.dataSup,
                    dataInf: this.dataInf,
                    termini: this.termini,
                    luogo: this.luogo,
                    pdn: this.pdn,
                    color: this.color,
                    supportoNoteTecniche: this.supportoNoteTecniche,
                    dimension: this.dimension,
                    notes: this.notes,
                    editing: true,
                };

                if (this.editing) {
                    await this.$store.dispatch(PHOTO_UPDATE, updateObject);
                } else {
                    await this.$store.dispatch(PHOTO_ADD, updateObject);
                }
                await this.$store.dispatch(PHOTO_GET);
                this.loading = false;
                this.isOpen = false;
            } catch (e) {
                // well...
                console.log(e);
                this.loading = false;
            }
        },
        open(this: any, data: PhotoInt) {
            if (data) {
                this.id = data.id;
                this.idPhoto = data.idPhoto;
                this.currentAlbum = (data?.album as AlbumInt)?.id ? (data?.album as AlbumInt)?.id : '';
                this.title = data.title;
                this.description = data.description;
                this.authorCopyright = data.authorCopyright;
                this.dataSup = data.dataSup;
                this.dataInf = data.dataInf;
                this.termini = data.termini;
                this.luogo = data.luogo;
                this.pdn = data.pdn;
                this.color = data.color;
                this.supportoNoteTecniche = data.supportoNoteTecniche;
                this.dimension = data.dimension;
                this.notes = data.notes;
                this.editing = true;
            } else {
                this.id = '';
                this.idPhoto = '';
                this.currentAlbum = '';
                this.title = '';
                this.description = '';
                this.authorCopyright = '';
                this.dataSup = '';
                this.dataInf = '';
                this.termini = '';
                this.luogo = '';
                this.pdn = '';
                this.color = '';
                this.supportoNoteTecniche = '';
                this.dimension = '';
                this.notes = '';
                this.editing = false;
            }
            this.isOpen = true;
        },
    },
});
