


























import { PaperInt } from '@/api/papers/types';
import { PAPER_GET } from '@/store/modules/papers';

export default {
    name: 'Papers',
    data(this: any) {
        return {
            open: false,
        };
    },
    mounted(this: any): void {
        this.$store.dispatch(PAPER_GET);
    },
    computed: {
        events(this:any): PaperInt[] {
            return this.$store.getters.papers.papers;
        },
    },
};
