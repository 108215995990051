




























































































import Vue from 'vue';
import { FIREBASE_LOGOUT } from '@/store/modules/fireauth';

export default Vue.extend({
    name: 'LoggedInMenu',
    methods: {
        logout(this: any) {
            this.$store.dispatch(FIREBASE_LOGOUT);
            this.drawer = false;
        },
    },
    computed: {
        isAdmin(this: any): boolean {
            return this.$store.getters.isAdmin;
        },
    },
});
