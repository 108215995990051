

















import Vue from 'vue';
import Viewer from '@/components/common/viewer/index.vue';

export default Vue.extend({
    name: 'Upload',
    data(this: any) {
        return {
            isOpen: false,
            loading: false,
            uploadFile: null,
        };
    },
    components: {
        Viewer,
    },
    props: {
        limit: Number,
        files: Array,
    },
    methods: {
        async open(this: any) {
            this.isOpen = true;
        },
        async close(this: any) {
            this.isOpen = false;
        },
        async setLoading(this: any, loading: boolean) {
            this.loading = loading;
        },
        async upload(this: any) {
            this.$emit('upload', this.uploadFile);
        },
    },
});
