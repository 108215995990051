



















import { FONDI_GET } from '@/store/modules/fondi';
import FondiTable from './components/table.vue';
import { FondiInt } from '@/api/fondi/types';

export default {
    name: 'Fondi',
    components: {
        FondiTable,
    },
    data(this: any) {
        return {
        };
    },
    async mounted(this: any): Promise<void> {
        this.$refs.fonditable.setLoading(true);
        await this.$store.dispatch(FONDI_GET);
        this.$refs.fonditable.setLoading(false);
    },
    computed: {
        fondi(this:any): FondiInt[] {
            return this.$store.getters.fondi;
        },
    },
};
