



















import AlbumTable from './components/table.vue';
import { AlbumInt } from '@/api/album/types';
import { ALBUM_GET } from '@/store/modules/album';

export default {
    name: 'Album',
    components: {
        AlbumTable,
    },
    data(this: any) {
        return {
        };
    },
    async mounted(this: any): Promise<void> {
        this.$refs.seriestable.setLoading(true);
        await this.$store.dispatch(ALBUM_GET);
        this.$refs.seriestable.setLoading(false);
    },
    computed: {
        albums(this:any): AlbumInt[] {
            return this.$store.getters.albums;
        },
    },
};
