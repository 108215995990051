var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"blockPhoto",attrs:{"elevation":"0"}},[_c('Filters',{on:{"filter":_vm.filter}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.idPhoto",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.openDetail(item); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.idPhoto)+" ")])]}},{key:"item.album",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.album ? ((item.album.externalId) + ": " + (item.album.title.substr(0, 40)) + "...") : '-')+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" INF: "+_vm._s(item.dataInf)+" "),_c('br'),_vm._v(" SUP: "+_vm._s(item.dataSup)+" "),_c('br')]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"blockPhoto__actions"},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.updateItem(item)}}},[_vm._v("aggiorna")]),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v("delete")]),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.selectedPhoto = item; _vm.$refs.upload.open(item)}}},[_vm._v("allegati")])],1)],1)]}}],null,true)}),_c('Edit',{ref:"editor",attrs:{"isopen":_vm.open},on:{"close":function($event){_vm.open = false}}}),(_vm.selectedPhoto)?_c('Detail',{ref:"mydetail",attrs:{"photo":_vm.selectedPhoto}}):_vm._e(),_c('Upload',{ref:"upload",attrs:{"files":[_vm.selectedPhoto.image].filter(function (item) { return Boolean(item); }),"limit":1},on:{"delete":_vm.deleteFile,"upload":_vm.uploadFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }