import axios, { AxiosResponse } from 'axios';
import get from 'lodash.get';
import { ApiResponse } from '@/api/types';
import { PaperInt, QueryInt, PaperResult } from '@/api/papers/types';
import GlobalBus, {
    GLOBAL_BUS_TYPES, INotification, IRedirect, NOTIFICATION_TYPE,
} from '@/services/globalbus';
import { UserInfoInt, UserInt } from '@/api/users/types';
import { FondiInt } from '@/api/fondi/types';
import { SerieInt } from '@/api/series/types';
import { AlbumInt } from '@/api/album/types';
import { PhotoInt, PhotoResult } from '@/api/photo/types';
import { LucidiInt, LucidiResult } from '@/api/lucidi/types';

const API_BASE_URL = process.env.VUE_APP_SERVER_BASE_URL;

const apiBoundaries = async <T>(logic: () => Promise<AxiosResponse<ApiResponse<T>>>): Promise<T> => {
    try {
        const res: AxiosResponse<ApiResponse<T>> = await logic();
        return res.data.data;
    } catch (e:any) {
        let errorString = '';
        const message = get(e, 'response.data.message', '');
        const code = get(e, 'response.status', '');
        if (message && code) {
            errorString = `${code}: ${message}`;
        } else {
            errorString = e.message;
        }

        // If the code is 401 means that we don't have enough permission hsomehow to see the page, therefor
        // we redirect the user to the no auth page and we LOGOUT the user if needed.
        if (code === 401) {
            GlobalBus.$emit(GLOBAL_BUS_TYPES.LOGOUT);
            GlobalBus.$emit(GLOBAL_BUS_TYPES.REDIRECT, { path: 'notauthorized' } as IRedirect);
            throw new Error(errorString);
        }
        if (code === 403) {
            GlobalBus.$emit(GLOBAL_BUS_TYPES.REDIRECT, { path: 'notauthorized' } as IRedirect);
            throw new Error(errorString);
        } else {
            GlobalBus.$emit(GLOBAL_BUS_TYPES.NOTIFICATION, { type: NOTIFICATION_TYPE.ERROR, message: errorString } as INotification);
            throw new Error(errorString);
        }
    }
};

const getPapers = async (options: QueryInt): Promise<PaperResult> => apiBoundaries(() => axios.get(`${API_BASE_URL}/papers?${new URLSearchParams(options as any).toString()}`));
const updatePaper = async (paper: PaperInt): Promise<PaperInt> => apiBoundaries(() => axios.put(`${API_BASE_URL}/papers?id=${paper.id}`, paper));
const insertPaper = async (paper: PaperInt): Promise<PaperInt> => apiBoundaries(() => axios.post(`${API_BASE_URL}/papers`, paper));
const deletePaper = async (objectID: string): Promise<PaperInt> => apiBoundaries(() => axios.delete(`${API_BASE_URL}/papers?id=${objectID}`));

const getUsers = async (): Promise<UserInt[]> => apiBoundaries(() => axios.get(`${API_BASE_URL}/users`));
const getMe = async (): Promise<UserInfoInt> => apiBoundaries(() => axios.get(`${API_BASE_URL}/users/me`));
const updateUserInfo = async (userId: string, userInfos: UserInfoInt): Promise<void> => apiBoundaries(() => axios.put(`${API_BASE_URL}/users?userId=${userId}`, { ...userInfos }));
const enableUser = async (userId: string): Promise<void> => apiBoundaries(() => axios.post(`${API_BASE_URL}/users?userId=${userId}&action=enableUser`));
const disableUser = async (userId: string): Promise<void> => apiBoundaries(() => axios.post(`${API_BASE_URL}/users?userId=${userId}&action=disableUser`));
const deleteUser = async (userId: string): Promise<void> => apiBoundaries(() => axios.delete(`${API_BASE_URL}/users?userId=${userId}`));

const getFondi = async (): Promise<FondiInt[]> => apiBoundaries(() => axios.get(`${API_BASE_URL}/fondi`));
const insertFondi = async (fondi: FondiInt): Promise<FondiInt> => apiBoundaries(() => axios.post(`${API_BASE_URL}/fondi`, fondi));
const updateFondi = async (fondi: FondiInt): Promise<FondiInt> => apiBoundaries(() => axios.put(`${API_BASE_URL}/fondi?id=${fondi.id}`, fondi));
const deleteFondi = async (objectID: string): Promise<FondiInt> => apiBoundaries(() => axios.delete(`${API_BASE_URL}/fondi?id=${objectID}`));

const getSerie = async (): Promise<SerieInt[]> => apiBoundaries(() => axios.get(`${API_BASE_URL}/series`));
const insertSerie = async (serie: SerieInt): Promise<SerieInt> => apiBoundaries(() => axios.post(`${API_BASE_URL}/series`, serie));
const updateSerie = async (serie: SerieInt): Promise<SerieInt> => apiBoundaries(() => axios.put(`${API_BASE_URL}/series?id=${serie.id}`, serie));
const deleteSerie = async (objectID: string): Promise<SerieInt> => apiBoundaries(() => axios.delete(`${API_BASE_URL}/series?id=${objectID}`));

const getAlbum = async (): Promise<AlbumInt[]> => apiBoundaries(() => axios.get(`${API_BASE_URL}/album`));
const insertAlbum = async (serie: AlbumInt): Promise<AlbumInt> => apiBoundaries(() => axios.post(`${API_BASE_URL}/album`, serie));
const updateAlbum = async (serie: AlbumInt): Promise<AlbumInt> => apiBoundaries(() => axios.put(`${API_BASE_URL}/album?id=${serie.id}`, serie));
const deleteAlbum = async (objectID: string): Promise<AlbumInt> => apiBoundaries(() => axios.delete(`${API_BASE_URL}/album?id=${objectID}`));

const uploadAsset = async (form: any): Promise<PaperInt> => apiBoundaries(() => axios.post(`${API_BASE_URL}/assets`, form));
const getSignedAssets = async (file: string[]): Promise<string[]> => apiBoundaries(() => axios.get(`${API_BASE_URL}/assets?assets=${file.join(',')}`));
const deleteAsset = async (file: string, paperId: string, category: string): Promise<void> => apiBoundaries(() => axios.delete(`${API_BASE_URL}/assets?asset=${file}&id=${paperId}&category=${category}`));

const getPhotos = async (options: QueryInt): Promise<PhotoResult> => apiBoundaries(() => axios.get(`${API_BASE_URL}/photo?${new URLSearchParams(options as any).toString()}`));
const updatePhoto = async (photo: PhotoInt): Promise<PhotoInt> => apiBoundaries(() => axios.put(`${API_BASE_URL}/photo?id=${photo.id}`, photo));
const insertPhoto = async (photo: PhotoInt): Promise<PhotoInt> => apiBoundaries(() => axios.post(`${API_BASE_URL}/photo`, photo));
const deletePhoto = async (objectID: string): Promise<PaperInt> => apiBoundaries(() => axios.delete(`${API_BASE_URL}/photo?id=${objectID}`));

const getLucidi = async (options: QueryInt): Promise<LucidiResult> => apiBoundaries(() => axios.get(`${API_BASE_URL}/lucidi?${new URLSearchParams(options as any).toString()}`));
const updateLucidi = async (lucidi: LucidiInt): Promise<LucidiInt> => apiBoundaries(() => axios.put(`${API_BASE_URL}/lucidi?id=${lucidi.id}`, lucidi));
const insertLucidi = async (lucidi: LucidiInt): Promise<LucidiInt> => apiBoundaries(() => axios.post(`${API_BASE_URL}/lucidi`, lucidi));
const deleteLucidi = async (objectID: string): Promise<LucidiInt> => apiBoundaries(() => axios.delete(`${API_BASE_URL}/lucidi?id=${objectID}`));
const getLucidiCategory = async (parent?: string): Promise<any> => apiBoundaries(() => axios.get(`${API_BASE_URL}/lucidicat?parent=${parent}`));

export default {
    getPapers,
    updatePaper,
    insertPaper,
    deletePaper,
    getUsers,
    updateUserInfo,
    enableUser,
    disableUser,
    deleteUser,
    getMe,
    getFondi,
    insertFondi,
    updateFondi,
    deleteFondi,
    getSerie,
    insertSerie,
    updateSerie,
    deleteSerie,
    uploadAsset,
    getSignedAssets,
    deleteAsset,
    getAlbum,
    insertAlbum,
    updateAlbum,
    deleteAlbum,
    getPhotos,
    updatePhoto,
    insertPhoto,
    deletePhoto,
    getLucidi,
    updateLucidi,
    insertLucidi,
    deleteLucidi,
    getLucidiCategory,
};
