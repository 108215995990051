

















































































import Vue from 'vue';
import { PAPER_GET, PAPER_ADD, PAPER_UPDATE } from '@/store/modules/papers';
import { SerieInt } from '@/api/series/types';
import { FondiInt } from '@/api/fondi/types';
import { PaperInt } from '@/api/papers/types';

export default Vue.extend({
    name: 'EditPaper',
    data(this: any) {
        return {
            valid: '',
            fondo: '',
            currentSerie: '',
            title: '',
            description: '',
            number: '',
            id: '',
            loading: false,
            dataSup: '',
            dataInf: '',
            fisica: '',
            sheet: '',
            versions: '',
            notes: '',
            rules: {
                required: (value: any) => !!value || 'Campo obbligatorio.',
                min: (min: number) => (v: any) => (v && v.length >= min) || `Mininimo ${min} caratteri`,
            },
            isOpen: false,
            editing: false,
        };
    },
    computed: {
        fondi(this: any) {
            return this.$store.getters.fondi;
        },
        serie(this: any) {
            return this.$store.getters.serie;
        },
    },
    methods: {
        selectSerie(this: any, itemText, queryText) {
            return (itemText.title.toLowerCase().indexOf(queryText.toLowerCase()) > -1);
        },
        async update(this: any) {
            try {
                this.loading = true;
                const updateObject = {
                    id: this.id,
                    title: this.title,
                    number: this.number,
                    description: this.description,
                    fondo: this.fondo,
                    serie: this.currentSerie,
                    dataInf: this.dataInf,
                    dataSup: this.dataSup,
                    fisica: this.fisica,
                    sheet: this.sheet,
                    versions: this.versions,
                    notes: this.notes,
                    editing: true,
                };

                if (this.editing) {
                    await this.$store.dispatch(PAPER_UPDATE, updateObject);
                } else {
                    await this.$store.dispatch(PAPER_ADD, updateObject);
                }
                await this.$store.dispatch(PAPER_GET);
                this.loading = false;
                this.isOpen = false;
            } catch (e) {
                // well...
                console.log(e);
                this.loading = false;
            }
        },
        open(this: any, data: PaperInt) {
            if (data) {
                this.id = data.id;
                this.title = data.title;
                this.number = data.number;
                this.description = data.description;
                this.fondo = (data?.fondo as FondiInt)?.id ? (data?.fondo as FondiInt)?.id : '';
                this.currentSerie = (data?.serie as SerieInt)?.id ? (data?.serie as SerieInt)?.id : '';
                this.dataInf = data.dataInf;
                this.dataSup = data.dataSup;
                this.fisica = data.fisica;
                this.sheet = data.sheet;
                this.versions = data.versions;
                this.notes = data.notes;
                this.editing = true;
            } else {
                this.id = '';
                this.title = '';
                this.number = '';
                this.description = '';
                this.fondo = '';
                this.currentSerie = '';
                this.dataInf = '';
                this.dataSup = '';
                this.fisica = '';
                this.sheet = '';
                this.versions = '';
                this.notes = '';
                this.editing = false;
            }
            this.isOpen = true;
        },
    },
});
