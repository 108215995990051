import axios from 'axios';
import { ActionContext } from 'vuex';
import {
    getAuth, createUserWithEmailAndPassword, User, signInWithEmailAndPassword, signOut, updateProfile, sendPasswordResetEmail, sendEmailVerification,
} from 'firebase/auth';
import GlobalBus, { GLOBAL_BUS_TYPES, INotification, NOTIFICATION_TYPE } from '@/services/globalbus';
import FireErrors from './fireerrors';
import FondazioneApi from '@/services/backend';
import { UserInfoInt } from '@/api/users/types';

export const FIREBASE_SEND_EMAIL_VERIFICATION = 'FIREBASE_SEND_EMAIL_VERIFICATION';
export const FIREBASE_REGISTER = 'FIREBASE_REGISTER';
export const FIREBASE_LOGIN = 'FIREBASE_LOGIN';
export const FIREBASE_LOGOUT = 'FIREBASE_LOGOUT';
export const FIREBASE_FORGOT_PASSWORD = 'FIREBASE_FORGOT_PASSWORD';
export const FIREBASE_SET_JWT_TOKEN = 'FIREBASE_SET_JWT_TOKEN';
export const FIREBASE_SET_CLAIM = 'FIREBASE_SET_CLAIM';
export const FIREBASE_USER_INFO = 'FIREBASE_USER_INFO';
export const FIREBASE_SET_USER_INFO = 'FIREBASE_SET_USER_INFO';

export const FIREBASE_IS_USER_AUTHENTICATED = 'FIREBASE_IS_USER_AUTHENTICATED';

const LOCAL_STORAGE_LOGGEDIN_FLAG = 'LOCAL_STORAGE_LOGGEDIN_FLAG';

export interface CustomClaim {
    admin: boolean;
    enabled: boolean;
}

interface IFireauth {
    isLoggedIn: boolean;
    isWaitingForUser: boolean;
    currentUser?: User;
    currentClaim?: CustomClaim;
    token?: string;
    userInfo?: UserInfoInt;
}

const showError = (code: string, message: string): null => {
    let errorMessage = message;
    if (code && FireErrors[code]) {
        errorMessage = FireErrors[code];
    }

    GlobalBus.$emit(GLOBAL_BUS_TYPES.NOTIFICATION, { type: NOTIFICATION_TYPE.ERROR, message: errorMessage } as INotification);
    return null;
};

export default {
    state: {
        isLoggedIn: false,
        isWaitingForUser: false,
        currentUser: null,
        currentClaim: null,
        userInfo: {},
        isEmailVerified: false,
        token: '',
    },
    mutations: {
        [FIREBASE_LOGIN](state: IFireauth, user: User): void {
            state.isLoggedIn = true;
            axios.defaults.headers.common.Authorization = `Bearer ${user.getIdToken()}`;
            state.currentUser = user;
            localStorage.setItem(LOCAL_STORAGE_LOGGEDIN_FLAG, '1');
        },
        [FIREBASE_LOGOUT](state: IFireauth): void {
            state.isLoggedIn = false;
            state.currentUser = undefined;
            localStorage.removeItem(LOCAL_STORAGE_LOGGEDIN_FLAG);
        },
        [FIREBASE_SET_JWT_TOKEN](state: IFireauth, token: string): void {
            state.token = token;
        },
        [FIREBASE_SET_CLAIM](state: IFireauth, claim: CustomClaim): void {
            state.currentClaim = claim;
        },
        [FIREBASE_USER_INFO](state: IFireauth, data: UserInfoInt): void {
            state.userInfo = data;
        },
    },
    getters: {
        isAdmin: (state: IFireauth): boolean => Boolean(state?.currentClaim?.admin),
        hasAccess: (state: IFireauth): boolean => Boolean(state?.currentClaim?.admin) || Boolean(state?.currentClaim?.enabled),
        isLoggedIn: (state: IFireauth): boolean => Boolean(state?.currentUser),
        userInfo: (state: IFireauth): UserInfoInt | undefined => state?.userInfo,
        isWaitingForUser: (state: IFireauth): boolean => state.isWaitingForUser,
        isEmailVerified: (state: IFireauth): boolean => Boolean(state?.currentUser?.emailVerified),
    },
    actions: {
        async [FIREBASE_REGISTER](context: ActionContext<IFireauth, unknown>, data: {email: string, password: string, name: string, phone: string, affiliation: string }): Promise<User | null> {
            try {
                const response = await createUserWithEmailAndPassword(getAuth(), data.email, data.password);

                // Updating user infos
                await FondazioneApi.updateUserInfo(response.user.uid, { name: data.name, phone: data.phone, affiliation: data.affiliation });

                context.commit(FIREBASE_LOGIN, response.user);
                await context.dispatch(FIREBASE_SET_JWT_TOKEN);
                GlobalBus.$emit(GLOBAL_BUS_TYPES.REDIRECT, { path: '/dashboard' });
                return response.user;
            } catch (e: any) {
                return showError(e.code, e.message);
            }
        },

        async [FIREBASE_LOGIN](context: ActionContext<IFireauth, unknown>, data: {email: string, password: string }): Promise<User | null> {
            try {
                const response = await signInWithEmailAndPassword(getAuth(), data.email, data.password);
                context.commit(FIREBASE_LOGIN, response.user);
                await context.dispatch(FIREBASE_SET_JWT_TOKEN);
                GlobalBus.$emit(GLOBAL_BUS_TYPES.REDIRECT, { path: '/dashboard' });
                return response.user;
            } catch (e: any) {
                return showError(e.code, e.message);
            }
        },

        async [FIREBASE_LOGOUT](context: ActionContext<IFireauth, unknown>): Promise<void> {
            try {
                await signOut(getAuth());
                context.commit(FIREBASE_LOGOUT);
                GlobalBus.$emit(GLOBAL_BUS_TYPES.REDIRECT, { path: '/' });
            } catch (e: any) {
                showError(e.code, e.message);
            }
        },
        async [FIREBASE_FORGOT_PASSWORD](context: ActionContext<IFireauth, unknown>, data: {email: string}): Promise<void> {
            try {
                await sendPasswordResetEmail(getAuth(), data.email, { url: 'http://localhost:8080/something' });
            } catch (e: any) {
                showError(e.code, e.message);
            }
        },
        async [FIREBASE_SET_USER_INFO](context: ActionContext<IFireauth, unknown>, data: {name: string, phone: string, affiliation: string}): Promise<void> {
            try {
                if (context.state.currentUser?.uid) {
                    await FondazioneApi.updateUserInfo(context.state.currentUser?.uid, data);
                    context.commit(FIREBASE_USER_INFO, { uid: context.state.currentUser?.uid, ...data });
                }
            } catch (e: any) {
                showError(e.code, e.message);
            }
        },
        async [FIREBASE_SET_JWT_TOKEN](context: ActionContext<IFireauth, unknown>): Promise<null> {
            if (context.state.isLoggedIn) {
                const token = await context.state.currentUser?.getIdToken(true);
                context.commit(FIREBASE_SET_JWT_TOKEN, token);
                axios.defaults.headers.common.token = token;

                const idTokenResult = await context.state.currentUser?.getIdTokenResult();
                if (idTokenResult) {
                    context.commit(FIREBASE_SET_CLAIM, idTokenResult.claims);
                }
                await context.dispatch(FIREBASE_USER_INFO);
            }

            return null;
        },
        async [FIREBASE_SEND_EMAIL_VERIFICATION](context: ActionContext<IFireauth, unknown>): Promise<null> {
            if (context.state.isLoggedIn && context.state.currentUser) {
                await sendEmailVerification(context.state.currentUser);
            }

            return null;
        },
        async [FIREBASE_USER_INFO](context: ActionContext<IFireauth, unknown>): Promise<null> {
            const data = await FondazioneApi.getMe();
            context.commit(FIREBASE_USER_INFO, data);
            return null;
        },
        async [FIREBASE_IS_USER_AUTHENTICATED](context: ActionContext<IFireauth, unknown>): Promise<User | null> {
            // Means user never logged in
            const localReminder = localStorage.getItem(LOCAL_STORAGE_LOGGEDIN_FLAG);
            if (!localReminder) return null;

            // Might happen we already have the user (i.e. client side navigation)
            const { currentUser } = getAuth();
            if (currentUser) {
                await currentUser.reload();
                return currentUser;
            }

            // Ok, we need to check if the user is logged in or not
            return new Promise((resolve: any) => {
                getAuth().onAuthStateChanged(async (user: User | null) => {
                    if (user) {
                        context.commit(FIREBASE_LOGIN, user);
                        await context.dispatch(FIREBASE_SET_JWT_TOKEN);
                        return resolve(user);
                    }

                    context.commit(FIREBASE_LOGOUT);
                    return resolve(null);
                });
            });
        },
    },
};
