



















import Vue from 'vue';
import { FONDI_ADD, FONDI_GET, FONDI_UPDATE } from '@/store/modules/fondi';
import { FondiInt } from '@/api/fondi/types';

export default Vue.extend({
    name: 'EditFondo',
    data(this: any) {
        return {
            valid: '',
            name: '',
            description: '',
            id: '',
            loading: false,
            rules: {
                required: (value: any) => !!value || 'Campo obbligatorio.',
                min: (min: number) => (v: any) => (v && v.length >= min) || `Mininimo ${min} caratteri`,
            },
            isOpen: false,
            editing: false,
        };
    },
    methods: {
        async update(this: any) {
            try {
                this.loading = true;
                if (this.editing) {
                    await this.$store.dispatch(FONDI_UPDATE, { id: this.id, name: this.name, description: this.description });
                } else {
                    await this.$store.dispatch(FONDI_ADD, { name: this.name, description: this.description });
                }
                await this.$store.dispatch(FONDI_GET);
                this.loading = false;
                this.isOpen = false;
            } catch (e) {
                // well...
                console.log(e);
                this.loading = false;
            }
        },
        open(this: any, fondo: FondiInt) {
            if (fondo) {
                this.id = fondo.id;
                this.name = fondo.name;
                this.description = fondo.description;
                this.editing = true;
            } else {
                this.id = '';
                this.name = '';
                this.description = '';
                this.editing = false;
            }
            this.isOpen = true;
        },
    },
});
