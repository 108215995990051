import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import MainLayout from '@/views/Public.vue';
import NoAuth from '@/pages/user/unauthorized.vue';
import Guard from './guards';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: MainLayout,
        children: [
            {
                path: '',
                name: 'Homepage',
                components: {
                    default: () => import(/* webpackChunkName: "homepage" */ '../pages/homepage.vue'),
                },
            },
            {
                path: 'login',
                name: 'Login',
                components: {
                    default: () => import(/* webpackChunkName: "login" */ '../pages/user/login.vue'),
                },
            },
            {
                path: 'register',
                name: 'Register',
                components: {
                    default: () => import(/* webpackChunkName: "register" */ '../pages/user/register.vue'),
                },
            },
            {
                path: 'forgot',
                name: 'Forgot password',
                components: {
                    default: () => import(/* webpackChunkName: "forgotpassword" */ '../pages/user/forgot.vue'),
                },
            },
        ],
    },
    {
        path: '/notauthorized',
        name: 'No auth',
        component: NoAuth,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: MainLayout,
        children: [
            {
                path: '',
                name: 'Documenti cartacei',
                components: {
                    default: () => import(/* webpackChunkName: "papers" */ '../pages/dashboard/papers/index.vue'),
                },
            },
            {
                path: 'media',
                name: 'Documenti multimediali',
                components: {
                    default: () => import(/* webpackChunkName: "media" */ '../pages/dashboard/media.vue'),
                },
            },
            {
                path: 'profile',
                name: 'Il tuo profilo',
                components: {
                    default: () => import(/* webpackChunkName: "media" */ '../pages/dashboard/profile.vue'),
                },
            },
            {
                path: 'fondi',
                name: 'I fondi',
                components: {
                    default: () => import(/* webpackChunkName: "fondi" */ '../pages/dashboard/fondi/index.vue'),
                },
            },
            {
                path: 'Serie',
                name: 'Le serie',
                components: {
                    default: () => import(/* webpackChunkName: "serie" */ '../pages/dashboard/serie/index.vue'),
                },
            },
            {
                path: 'Album',
                name: 'album',
                components: {
                    default: () => import(/* webpackChunkName: "album" */ '../pages/dashboard/album/index.vue'),
                },
            },
            {
                path: 'photo',
                name: 'photo',
                components: {
                    default: () => import(/* webpackChunkName: "photo" */ '../pages/dashboard/photo/index.vue'),
                },
            },
            {
                path: 'lucidi',
                name: 'ludici',
                components: {
                    default: () => import(/* webpackChunkName: "lucidiMain" */ '../pages/dashboard/lucidi/mainPage.vue'),
                },
            },
            {
                path: 'lucidi/:category',
                name: 'ludiciMainCat',
                components: {
                    default: () => import(/* webpackChunkName: "lucidicat" */ '../pages/dashboard/lucidi/mainCategory.vue'),
                },
            },
            {
                path: 'lucidi/:category/:subcategory',
                name: 'ludiciMainCat',
                components: {
                    default: () => import(/* webpackChunkName: "lucidisubcat" */ '../pages/dashboard/lucidi/index.vue'),
                },
            },
            {
                path: 'users',
                name: 'Gestione utenti',
                components: {
                    default: () => import(/* webpackChunkName: "userspage" */ '../pages/dashboard/users/index.vue'),
                },
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => { document.title = 'Fondazione.live'; next(); });
router.beforeEach(Guard(['/', '/login', '/notauthorized', '/register', '/forgot']));

export default router;
