import Vue from 'vue';
import Vuex from 'vuex';
import fireauth from '@/store/modules/fireauth';
import papers from '@/store/modules/papers';
import fondi from '@/store/modules/fondi';
import serie from '@/store/modules/serie';
import general from '@/store/modules/general';
import users from '@/store/modules/users';
import assets from '@/store/modules/asset';
import album from '@/store/modules/album';
import photo from '@/store/modules/photo';
import lucidi from '@/store/modules/lucidi';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        fireauth,
        papers,
        general,
        users,
        fondi,
        serie,
        assets,
        album,
        photo,
        lucidi,
    },
});
