import FondazioneApi from '@/services/backend';
import { AlbumInt } from '@/api/album/types';

export const ALBUM_GET = 'ALBUM_GET';
export const ALBUM_ADD = 'ALBUM_ADD';
export const ALBUM_UPDATE = 'ALBUM_UPDATE';
export const ALBUM_DELETE = 'ALBUM_DELETE';

interface AlbumState {
    albums: AlbumInt[];
}

export interface actionsParams {
    commit: (action: string, payload: unknown) => unknown,
    state: AlbumState,
    getters: any,
    dispatch: any,
}

export default {
    state: {
        albums: [],
    },
    mutations: {
        [ALBUM_GET](state: AlbumState, albums: AlbumInt[]): void {
            state.albums = albums;
        },
    },

    getters: {
        albums: (state: AlbumState): AlbumInt[] => state.albums,
    },

    actions: {
        async [ALBUM_GET]({ commit }: actionsParams): Promise<void> {
            commit(ALBUM_GET, []);
            const album: AlbumInt[] = await FondazioneApi.getAlbum();
            commit(ALBUM_GET, album);
        },
        async [ALBUM_ADD]({ commit }: actionsParams, album: AlbumInt): Promise<void> {
            await FondazioneApi.insertAlbum(album);
        },
        async [ALBUM_UPDATE]({ commit }: actionsParams, album: AlbumInt): Promise<void> {
            await FondazioneApi.updateAlbum(album);
        },
        async [ALBUM_DELETE]({ commit }: actionsParams, id: string): Promise<void> {
            await FondazioneApi.deleteAlbum(id);
        },
    },
};
