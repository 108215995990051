export default {
    'auth/claims-too-large': 'Claim troppo grande (max 1000 char)',
    'auth/email-already-exists': 'Email gia registrata',
    'auth/email-already-in-use': 'Email gia utilizzata',
    'auth/missing-email': 'Email non trovata',
    'auth/id-token-expired': 'Token scaduto',
    'auth/id-token-revoked': 'Id revocato',
    'auth/insufficient-permission': 'Permessi insufficienti',
    'auth/internal-error': 'Errore interno',
    'auth/invalid-argument': 'Argomento non valido',
    'auth/invalid-claims': 'Claim invalido',
    'auth/invalid-continue-uri': 'Url invalida',
    'auth/invalid-creation-time': 'Data di creazione invalida',
    'auth/invalid-credential': 'Credenziali non valide',
    'auth/invalid-disabled-field': 'Campo disabilitato invalido',
    'auth/invalid-display-name': 'Nome utent invalido',
    'auth/invalid-dynamic-link-domain': 'Link dinamoco invalido',
    'auth/invalid-email': 'Email invalida',
    'auth/invalid-email-verified': 'Flag verifica email non valida',
    'auth/invalid-hash-algorithm': 'Algoritmodi hash invalido',
    'auth/invalid-hash-block-size': 'Block size hash invalido',
    'auth/invalid-hash-derived-key-length': 'Hash derived key invalida',
    'auth/invalid-hash-key': 'Hash key invalida',
    'auth/invalid-hash-memory-cost': 'Memory cost invalida',
    'auth/invalid-hash-parallelization': 'Hash parallelizzato invalido',
    'auth/invalid-hash-rounds': 'Hash rounds invalido',
    'auth/invalid-hash-salt-separator': 'Salt seprator invalido',
    'auth/invalid-id-token': 'Id token invalido',
    'auth/invalid-last-sign-in-time': 'Last sign in invalido',
    'auth/invalid-page-token': 'Page topken invalido',
    'auth/invalid-password': 'Password invalida',
    'auth/invalid-password-hash': 'Password hash invalido',
    'auth/invalid-password-salt': 'Password salt invalido',
    'auth/invalid-phone-number': 'Numero telefono invalido',
    'auth/invalid-photo-url': 'Photo url invalido',
    'auth/invalid-provider-data': 'Data provider invalido',
    'auth/invalid-provider-id': 'Provider id invalido',
    'auth/invalid-oauth-responsetype': 'Oauth response invalida',
    'auth/invalid-session-cookie-duration': 'Session cookie invalida',
    'auth/invalid-uid': 'Uid invalido',
    'auth/invalid-user-import': 'User import invalido',
    'auth/maximum-user-count-exceeded': 'User count exceeded',
    'auth/missing-android-pkg-name': 'Android package name nn valido',
    'auth/missing-continue-uri': 'Url di continuazione mancante',
    'auth/missing-hash-algorithm': 'Algoritmo di hash non valido',
    'auth/missing-ios-bundle-id': 'Ios bundle id mancante',
    'auth/missing-uid': 'Uid mancante',
    'auth/missing-oauth-client-secret': 'Oauth clint secret mancante',
    'auth/operation-not-allowed': 'Operazione non consentita',
    'auth/phone-number-already-exists': 'Numero di telefono esistente',
    'auth/project-not-found': 'Progetto non trovato',
    'auth/reserved-claims': 'Claim riservato',
    'auth/session-cookie-expired': 'Sessione scaduta',
    'auth/session-cookie-revoked': 'Sessione revokata',
    'auth/uid-already-exists': 'Uid esistente',
    'auth/unauthorized-continue-uri': 'Url non autorizzata',
    'auth/user-not-found': 'Utente non trovato',
};
