


















import FondazioneNotification from '@/components/common/notifications.vue';
import FondazioneHeader from '../components/common/header/index.vue';
import FondazioneFooter from '../components/common/footer/index.vue';
import LineLoader from '@/components/common/lineLoader/index.vue';

export default {
    name: 'home',
    components: {
        FondazioneHeader,
        FondazioneNotification,
        FondazioneFooter,
        LineLoader,
    },
    computed: {
        isHome(this: any): boolean { return this.$route.path === '/'; },
    },
};
