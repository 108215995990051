






























































import Vue from 'vue';

export default Vue.extend({
    name: 'PaperFilters',
    data(this: any) {
        return {
            fondo: '',
            search: '',
            currentSerie: '',
            dataInf: '',
            dataSup: '',
        };
    },
    computed: {
        fondi(this: any) {
            return this.$store.getters.fondi;
        },
        serie(this: any) {
            return this.$store.getters.serie;
        },
    },
    methods: {
        filter(this: any) {
            const filters: any = {};
            if (this.fondo) filters.fondo = this.fondo;
            if (this.currentSerie) filters.serie = this.currentSerie;
            if (this.search) filters.q = this.search;
            if (this.dataInf) filters.dataInf = this.dataInf;
            if (this.dataSup) filters.dataSup = this.dataSup;

            this.$emit('filter', filters);
        },
        selectSerie(this: any, itemText, queryText) {
            return (itemText.title.toLowerCase().indexOf(queryText.toLowerCase()) > -1);
        },
    },
});

