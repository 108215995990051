
























import { LUCIDI_GET_CAT } from '@/store/modules/lucidi';

export default {
    name: 'LucidiMain',
    components: {
    },
    data(this: any) {
        return {
            categories: [{ id: 1, name: '' }],
        };
    },
    async mounted(this: any): Promise<void> {
        this.categories = await this.$store.dispatch(LUCIDI_GET_CAT);
    },
    computed: {
    },
};
