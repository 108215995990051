



















import { SERIE_GET } from '@/store/modules/serie';
import SerieTable from './components/table.vue';
import { SerieInt } from '@/api/series/types';
import { FONDI_GET } from '@/store/modules/fondi';

export default {
    name: 'Serie',
    components: {
        SerieTable,
    },
    data(this: any) {
        return {
        };
    },
    async mounted(this: any): Promise<void> {
        this.$refs.seriestable.setLoading(true);
        await this.$store.dispatch(SERIE_GET);
        await this.$store.dispatch(FONDI_GET);
        this.$refs.seriestable.setLoading(false);
    },
    computed: {
        serie(this:any): SerieInt[] {
            return this.$store.getters.serie;
        },
    },
};
