







import Vue from 'vue';
import GlobalBus, {
    GLOBAL_BUS_TYPES, INotification, IRedirect, NOTIFICATION_TYPE,
} from '@/services/globalbus';
import { FIREBASE_LOGOUT } from '@/store/modules/fireauth';

export default Vue.extend({
    name: 'Notification',
    data() {
        return {
            msg: '',
            visible: false,
        };
    },
    methods: {
        show(msg: string) {
            this.msg = msg;
            this.visible = true;
            setTimeout(() => { this.visible = false; }, 6000);
        },
    },
    mounted(this: any): void {
        GlobalBus.$on(GLOBAL_BUS_TYPES.NOTIFICATION, (data: INotification) => {
            if (data.type === NOTIFICATION_TYPE.ERROR) {
                this.show(data.message);
            }
        });

        GlobalBus.$on(GLOBAL_BUS_TYPES.REDIRECT, (data: IRedirect) => {
            if (data.path) {
                this.$router.push({ path: data.path });
            }
        });

        GlobalBus.$on(GLOBAL_BUS_TYPES.LOGOUT, async () => {
            await this.$store.dispatch(FIREBASE_LOGOUT);
        });
    },
});
