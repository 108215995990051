









import Vue from 'vue';
import Loader from '@/components/common/loader.vue';

export default Vue.extend({
    components: {
        Loader,
    },
});
