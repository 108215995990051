




















import Vue from 'vue';
import { SERIE_DELETE, SERIE_GET } from '@/store/modules/serie';
import SerieEdit from './edit.vue';
import { SerieInt } from '@/api/series/types';
import { ASSET_DELETE, ASSET_UPLOAD } from '@/store/modules/asset';
import Upload from '@/components/common/uploader/index.vue';

export default Vue.extend({
    name: 'SerieTable',
    components: {
        SerieEdit,
        Upload,
    },
    data(this: any) {
        return {
            headers: [
                { text: 'Nome', value: 'title' },
                {
                    text: 'Descrizione', value: 'description', sortable: false,
                },
                {
                    text: 'Fondo', value: 'fondo', sortable: false,
                },
                {
                    text: 'Azioni', value: 'actions', sortable: false, align: 'end', width: '10%',
                },
            ],
            search: '',
            loading: false,
            open: false,
            selectedItem: '',
        };
    },
    props: ['items'],
    methods: {
        add(this: any) {
            this.$refs.editor.open();
        },
        setLoading(this: any, loading: boolean) {
            this.loading = loading;
        },
        updateItem(this: any, item: SerieInt) {
            this.$refs.editor.open(item);
        },
        async deleteItem(this: any, id: string) {
            this.loading = true;
            await this.$store.dispatch(SERIE_DELETE, id);
            await this.$store.dispatch(SERIE_GET, id);
            this.loading = false;
        },
        async deleteFile(this: any, item: string) {
            this.$refs.upload.setLoading(true);
            await this.$store.dispatch(ASSET_DELETE, { asset: item, id: this.selectedItem.id, category: 'series' });
            await this.$store.dispatch(SERIE_GET);
            this.$refs.upload.setLoading(false);
            this.selectedItem.image = '';
        },
        async uploadFile(this: any, file: File) {
            this.$refs.upload.setLoading(true);
            await this.$store.dispatch(ASSET_UPLOAD, { file, id: this.selectedItem.id, category: 'series' });
            await this.$store.dispatch(SERIE_GET);
            this.$refs.upload.setLoading(false);
            this.selectedItem.image = `series/${file.name}`;
        },
    },
});
