import FondazioneApi from '@/services/backend';
import { SerieInt } from '@/api/series/types';
import { PaperInt } from '@/api/papers/types';

export const ASSET_UPLOAD = 'ASSET_UPLOAD';
export const ASSET_SET_SIGNED_URL = 'ASSET_SET_SIGNED_URL';
export const ASSET_DELETE = 'ASSET_DELETE';

interface SerieState {
    serie: SerieInt[];
}

export interface actionsParams {
    commit: (action: string, payload: unknown) => unknown,
    state: SerieState,
    getters: any,
    dispatch: any,
}

export default {
    state: {
    },

    actions: {
        async [ASSET_UPLOAD]({ commit }: actionsParams, data: {file: File, id: string, category: string }): Promise<PaperInt> {
            return new Promise((resolve) => {
                const myFileReader = new FileReader();
                myFileReader.onload = async (e: any) => {
                    const formData = new FormData();
                    formData.append('id', data.id);
                    formData.append('name', data.file.name);
                    formData.append('preview', e.target.result);
                    formData.append('category', data.category);
                    const res = await FondazioneApi.uploadAsset({
                        id: data.id,
                        name: data.file.name,
                        preview: e.target.result,
                        category: data.category,
                    });
                    resolve(res);
                };

                return myFileReader.readAsDataURL(data.file);
            });
        },
        async [ASSET_DELETE]({ commit }: actionsParams, data: {asset: string, id: string, category: string}): Promise<void> {
            return FondazioneApi.deleteAsset(data.asset, data.id, data.category);
        },
        async [ASSET_SET_SIGNED_URL]({ commit }: actionsParams, filename: string[]): Promise<string[]> {
            return FondazioneApi.getSignedAssets(filename);
        },
    },
};
