import { getAnalytics, logEvent } from 'firebase/analytics';
import FondazioneApi from '@/services/backend';
import { PaperInt, PaperResult, QueryInt } from '@/api/papers/types';

export const PAPER_GET = 'PAPER_GET';
export const PAPER_ADD = 'PAPER_ADD';
export const PAPER_UPDATE = 'PAPER_UPDATE';
export const PAPER_DELETE = 'PAPER_DELETE';
export const PAPER_GET_TOTAL_COUNT = 'PAPER_GET_TOTAL_COUNT';

interface PaperState {
    papers: PaperInt[];
    totalCount: number;
}

export interface actionsParams {
    commit: (action: string, payload: unknown) => unknown,
    state: PaperState,
    getters: any,
    dispatch: any,
}

export default {
    state: {
        papers: [],
        totalCount: 0,
    },
    mutations: {
        [PAPER_GET](state: PaperState, papers: PaperInt[]): void {
            state.papers = papers;
        },
        [PAPER_GET_TOTAL_COUNT](state: PaperState, totalCount: number): void {
            state.totalCount = totalCount;
        },
    },

    getters: {
        papers: (state: PaperState): PaperInt[] => state.papers,
        papersTotalCount: (state: PaperState): number => state.totalCount,
    },

    actions: {
        async [PAPER_GET]({ commit }: actionsParams, options: QueryInt): Promise<void> {
            commit(PAPER_GET, []);
            const papers: PaperResult = await FondazioneApi.getPapers(options);
            commit(PAPER_GET, papers.items);
            commit(PAPER_GET_TOTAL_COUNT, papers.totalCount);
            try {
                const analytics = getAnalytics();
                const res = await logEvent(analytics, 'view_item_list', {
                    item_list_id: 'papers',
                    item_list_name: 'papers',
                    page: options?.page,
                    perPage: options?.itemsPerPage,
                    filer_number: options?.number,
                    filer_title: options?.title,
                    filer_free_search: options?.q,
                    filer_serie: options?.serie,
                    filer_fondo: options?.fondo,
                    filer_dataInf: options?.dataInf,
                    filer_dataSup: options?.dataSup,
                    items: papers.items,
                });
                console.log(res);
                console.log('Sending done!');
            } catch (e) {
                console.log(e);
            }
        },
        async [PAPER_ADD]({ commit }: actionsParams, serie: PaperInt): Promise<void> {
            await FondazioneApi.insertPaper(serie);
        },
        async [PAPER_UPDATE]({ commit }: actionsParams, serie: PaperInt): Promise<void> {
            await FondazioneApi.updatePaper(serie);
        },
        async [PAPER_DELETE]({ commit }: actionsParams, fondoId: string): Promise<void> {
            await FondazioneApi.deletePaper(fondoId);
        },
    },
};
