import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';

import Vuetify, {
    VDatePicker, VImg, VRow, VCol,
} from 'vuetify/lib';
import * as styles from '@/styles/exported.scss';

Vue.use(Vuetify, {
    icons: {
        iconfont: 'md',
    },
    components: {
        VDatePicker,
        VImg,
        VRow,
        VCol,
    },
    directives: {
    },
});

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: {
                    base: styles.primary,
                    lighten1: styles.primaryLight1,
                    lighten2: styles.primaryLight2,
                    darken1: styles.primaryDark1,
                },
                secondary: {
                    base: styles.secondary,
                    lighten1: styles.secondaryLight1,
                    darken1: styles.secondaryDark1,
                },
                activeAction: {
                    base: styles.activeAction,
                    lighten1: styles.activeActionLight1,
                    darken1: styles.activeActionDark1,
                },
                passingAction: {
                    base: styles.passingAction,
                    lighten1: styles.passingActionLight1,
                    darken1: styles.passingActionDark1,
                },
                suggestedAction: {
                    base: styles.suggestedAction,
                    lighten1: styles.suggestedActionLight1,
                    darken1: styles.suggestedActionDark1,
                },
            },
        },
    },
});
