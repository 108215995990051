



















import Vue from 'vue';

export default Vue.extend({
    name: 'PhotoFilters',
    data(this: any) {
        return {
            search: '',
        };
    },
    methods: {
        filter(this: any) {
            const filters: any = {};
            if (this.search) filters.q = this.search;

            this.$emit('filter', filters);
        },
    },
});

