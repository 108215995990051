






















import Vue from 'vue';
import { ALBUM_DELETE, ALBUM_GET } from '@/store/modules/album';
import Edit from './edit.vue';
import { SerieInt } from '@/api/series/types';

export default Vue.extend({
    name: 'AlbumTable',
    components: {
        Edit,
    },
    data(this: any) {
        return {
            headers: [
                { text: 'Ext. Id', value: 'externalId' },
                {
                    text: 'Title', value: 'title', sortable: false,
                },
                {
                    text: 'Content', value: 'content', sortable: false,
                },
                {
                    text: 'Author/Copyright', value: 'authorCopy', sortable: false,
                },
                {
                    text: 'Termini', value: 'termini', sortable: false,
                },
                {
                    text: 'Luogo', value: 'luogo', sortable: false,
                },
                {
                    text: 'Date', value: 'date', sortable: false,
                },
                {
                    text: 'Note', value: 'note', sortable: false,
                },
                {
                    text: 'Azioni', value: 'actions', sortable: false, align: 'end', width: '10%',
                },
            ],
            search: '',
            loading: false,
            open: false,
            selectedItem: '',
        };
    },
    props: ['items'],
    methods: {
        add(this: any) {
            this.$refs.editor.open();
        },
        setLoading(this: any, loading: boolean) {
            this.loading = loading;
        },
        updateItem(this: any, item: SerieInt) {
            this.$refs.editor.open(item);
        },
        async deleteItem(this: any, id: string) {
            this.loading = true;
            await this.$store.dispatch(ALBUM_DELETE, id);
            await this.$store.dispatch(ALBUM_GET, id);
            this.loading = false;
        },
    },
});
