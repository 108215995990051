



















import { PAPER_GET } from '@/store/modules/papers';
import PapersTable from './components/table.vue';
import { SerieInt } from '@/api/series/types';
import { FONDI_GET } from '@/store/modules/fondi';
import { SERIE_GET } from '@/store/modules/serie';

export default {
    name: 'Serie',
    components: {
        PapersTable,
    },
    data(this: any) {
        return {
        };
    },
    async mounted(this: any): Promise<void> {
        this.$refs.seriestable.setLoading(true);
        await this.$store.dispatch(PAPER_GET);
        await this.$store.dispatch(FONDI_GET);
        await this.$store.dispatch(SERIE_GET);
        this.$refs.seriestable.setLoading(false);
    },
    computed: {
        isAdmin(this: any): boolean {
            return this.$store.getters.isAdmin;
        },
        papers(this:any): SerieInt[] {
            return this.$store.getters.papers;
        },
        totalCount(this:any): SerieInt[] {
            return this.$store.getters.papersTotalCount;
        },
    },
};
