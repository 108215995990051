







































import Vue from 'vue';
import { PAPER_GET, PAPER_DELETE } from '@/store/modules/papers';
import PaperEdit from './edit.vue';
import PaperDetail from './detail.vue';
import Upload from '@/components/common/uploader/index.vue';
import { SerieInt } from '@/api/series/types';
import Filters from './filters.vue';
import { ASSET_DELETE, ASSET_UPLOAD } from '@/store/modules/asset';

export default Vue.extend({
    name: 'PaperTable',
    components: {
        PaperEdit,
        Filters,
        PaperDetail,
        Upload,
    },
    data(this: any) {
        return {
            selectedPaper: '',
            detailOpen: false,
            filters: '',

            search: '',
            loading: false,
            open: false,
            options: {},
        };
    },
    props: ['items', 'totalCount'],
    watch: {
        options: {
            handler(this: any) {
                this.getItems();
            },
            deep: true,
        },
    },
    computed: {
        isAdmin(this: any): boolean {
            return this.$store.getters.isAdmin;
        },
        headers(this: any) {
            const items: any = [
                { text: 'Num', value: 'number', width: '100px' },
                {
                    text: 'Titolo', value: 'title', sortable: false,
                },
                {
                    text: 'Descrizione', value: 'description', sortable: false,
                },
                {
                    text: 'Serie', value: 'serie',
                },
                {
                    text: 'Fondo', value: 'fondo',
                },
                {
                    text: 'Date', value: 'date', width: '100px',
                },
            ];

            if (this.isAdmin) {
                items.push({
                    text: 'Azioni', value: 'actions', sortable: false, align: 'end', width: '10%',
                });
            }

            return items;
        },
    },
    methods: {
        async getItems(this: any) {
            const {
                page, itemsPerPage, sortBy, sortDesc,
            } = this.options as any;
            this.loading = true;
            await this.$store.dispatch(PAPER_GET, {
                page: (page - 1), itemsPerPage: parseInt(itemsPerPage || '10', 10), sortBy, sortDesc, ...this.filters,
            });
            this.loading = false;
        },
        add(this: any) {
            this.$refs.editor.open();
        },
        setLoading(this: any, loading: boolean) {
            this.loading = loading;
        },
        updateItem(this: any, item: SerieInt) {
            this.$refs.editor.open(item);
        },
        async deleteItem(this: any, id: string) {
            this.loading = true;
            await this.$store.dispatch(PAPER_DELETE, id);
            await this.$store.dispatch(PAPER_GET);
            this.loading = false;
        },
        async filter(this: any, filters: any) {
            this.filters = filters;
            this.getItems();
        },
        async deleteFile(this: any, item: string) {
            this.$refs.upload.setLoading(true);
            await this.$store.dispatch(ASSET_DELETE, { asset: item, id: this.selectedPaper.id, category: 'papers' });
            await this.$store.dispatch(PAPER_GET);
            this.$refs.upload.setLoading(false);
            this.selectedPaper.image = '';
        },
        async uploadFile(this: any, file: File) {
            this.$refs.upload.setLoading(true);
            await this.$store.dispatch(ASSET_UPLOAD, { file, id: this.selectedPaper.id, category: 'papers' });
            await this.$store.dispatch(PAPER_GET);
            this.$refs.upload.setLoading(false);
            this.selectedPaper.image = `papers/${file.name}`;
        },
    },
});
