


















import { USERS_GET } from '@/store/modules/users';
import { UserInt } from '@/api/users/types';
import UserTable from './components/table.vue';

export default {
    name: 'Papers',
    components: {
        UserTable,
    },
    data(this: any) {
        return {
            open: false,
        };
    },
    async mounted(this: any): Promise<void> {
        this.$refs.table.setLoading(true);
        await this.$store.dispatch(USERS_GET);
        this.$refs.table.setLoading(false);
    },
    computed: {
        users(this:any): UserInt[] {
            return this.$store.getters.users;
        },
    },
};
