import { getAnalytics, logEvent } from 'firebase/analytics';
import FondazioneApi from '@/services/backend';
import { LucidiInt, LucidiResult, QueryInt } from '@/api/lucidi/types';

export const LUCIDI_GET = 'LUCIDI_GET';
export const LUCIDI_GET_CAT = 'LUCIDI_GET_CAT';
export const LUCIDI_ADD = 'LUCIDI_ADD';
export const LUCIDI_UPDATE = 'LUCIDI_UPDATE';
export const LUCIDI_DELETE = 'LUCIDI_DELETE';
export const LUCIDI_GET_TOTAL_COUNT = 'LUCIDI_GET_TOTAL_COUNT';

interface PhotoState {
    lucidi: LucidiInt[];
    totalCount: number;
}

export interface actionsParams {
    commit: (action: string, payload: unknown) => unknown,
    state: PhotoState,
    getters: any,
    dispatch: any,
}

export default {
    state: {
        lucidi: [],
        totalCount: 0,
    },
    mutations: {
        [LUCIDI_GET](state: PhotoState, lucidi: LucidiInt[]): void {
            state.lucidi = lucidi;
        },
        [LUCIDI_GET_TOTAL_COUNT](state: PhotoState, totalCount: number): void {
            state.totalCount = totalCount;
        },
    },

    getters: {
        lucidi: (state: PhotoState): LucidiInt[] => state.lucidi,
        lucidiTotalCount: (state: PhotoState): number => state.totalCount,
    },

    actions: {
        async [LUCIDI_GET]({ commit }: actionsParams, options: QueryInt): Promise<void> {
            commit(LUCIDI_GET, []);
            const lucidi: LucidiResult = await FondazioneApi.getLucidi(options);
            commit(LUCIDI_GET, lucidi.items);
            commit(LUCIDI_GET_TOTAL_COUNT, lucidi.totalCount);
            try {
                const analytics = getAnalytics();
                await logEvent(analytics, 'view_item_list', {
                    item_list_id: 'photos',
                    item_list_name: 'photos',
                    page: options?.page,
                    perPage: options?.itemsPerPage,
                    filer_category: options?.category,
                    filer_free_search: options?.q,
                    filer_subCategory: options?.subcategory,
                    items: lucidi.items,
                });
                console.log('Sending done!');
            } catch (e) {
                console.log(e);
            }
        },
        async [LUCIDI_ADD]({ commit }: actionsParams, lucidi: LucidiInt): Promise<void> {
            await FondazioneApi.insertLucidi(lucidi);
        },
        async [LUCIDI_UPDATE]({ commit }: actionsParams, lucidi: LucidiInt): Promise<void> {
            await FondazioneApi.updateLucidi(lucidi);
        },
        async [LUCIDI_DELETE]({ commit }: actionsParams, lucidiId: string): Promise<void> {
            await FondazioneApi.deleteLucidi(lucidiId);
        },
        async [LUCIDI_GET_CAT]({ commit }: actionsParams, parent: string): Promise<void> {
            const res = await FondazioneApi.getLucidiCategory(parent);
            return res;
        },
    },
};
