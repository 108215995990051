

































































export default {
    name: 'homepage',
    components: {
    },
    data(this: any) {
        return {
            showall: false,
        };
    },
    computed: {
        waiting(this: any): boolean {
            return this.$store.getters.isWaitingForUser;
        },
        actionText(this: any): string {
            return this.$store.getters.isLoggedIn ? 'Vai alla console' : 'Inizia ora';
        },
    },
    methods: {
        start(this: any) {
            if (this.$store.getters.isLoggedIn) {
                this.$router.push('/dashboard');
            } else {
                this.$router.push('/login');
            }
        },
    },
};
