





























import Vue from 'vue';
import { SERIE_ADD, SERIE_GET, SERIE_UPDATE } from '@/store/modules/serie';
import { SerieInt } from '@/api/series/types';
import { FondiInt } from '@/api/fondi/types';

export default Vue.extend({
    name: 'EditFondo',
    data(this: any) {
        return {
            valid: '',
            fondo: '',
            title: '',
            description: '',
            id: '',
            loading: false,
            rules: {
                required: (value: any) => !!value || 'Campo obbligatorio.',
                min: (min: number) => (v: any) => (v && v.length >= min) || `Mininimo ${min} caratteri`,
            },
            isOpen: false,
            editing: false,
        };
    },
    computed: {
        fondi(this: any) {
            return this.$store.getters.fondi;
        },
    },
    methods: {
        async update(this: any) {
            try {
                this.loading = true;
                if (this.editing) {
                    await this.$store.dispatch(SERIE_UPDATE, {
                        id: this.id, title: this.title, description: this.description, fondo: this.fondo,
                    });
                } else {
                    await this.$store.dispatch(SERIE_ADD, { title: this.title, description: this.description, fondo: this.fondo });
                }
                await this.$store.dispatch(SERIE_GET);
                this.loading = false;
                this.isOpen = false;
            } catch (e) {
                // well...
                console.log(e);
                this.loading = false;
            }
        },
        open(this: any, serie: SerieInt) {
            if (serie) {
                this.id = serie.id;
                this.title = serie.title;
                this.fondo = (serie?.fondo as FondiInt)?.id ? (serie?.fondo as FondiInt)?.id : '';
                this.description = serie.description;
                this.editing = true;
            } else {
                this.id = '';
                this.title = '';
                this.description = '';
                this.fondo = '';
                this.editing = false;
            }
            this.isOpen = true;
        },
    },
});
