
































































import Vue from 'vue';
// import Viewer from '@/components/common/viewer/index.vue';

export default Vue.extend({
    name: 'Detail',
    data(this: any) {
        return {
            isOpen: false,
            isPreviewOpen: false,
            loading: false,
            url: '',
            icon: 'mdi-file-png-box',
        };
    },
    methods: {
        open(this: any) {
            this.isOpen = true;
        },
    },
    props: ['lucido'],
    components: {
        // Viewer,
    },
});
