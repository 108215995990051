import FondazioneApi from '@/services/backend';
import { UserInt } from '@/api/users/types';
import { loadingWrapper } from './general';

export const USERS_GET = 'USERS_GET';
export const USERS_GET_ME = 'USERS_GET_ME';
export const USERS_ENABLE = 'USERS_ENABLE';
export const USERS_DISABLE = 'USERS_DISABLE';
export const USERS_DELETE = 'USERS_DELETE';

interface UsersState {
    users: UserInt[];
}

interface actionsParams {
    commit: (action: string, payload: unknown) => unknown,
    state: UsersState,
    getters: any,
    dispatch: any,
}

export default {
    state: {
        users: [],
    },
    mutations: {
        [USERS_GET](state: UsersState, users: UserInt[]): void {
            state.users = users;
        },
    },

    getters: {
        users: (state: UsersState): UserInt[] => state.users,
    },

    actions: {
        async [USERS_GET]({ commit }: actionsParams): Promise<void> {
            try {
                await loadingWrapper(commit, async () => {
                    commit(USERS_GET, []);
                    const users: UserInt[] = await FondazioneApi.getUsers();
                    commit(USERS_GET, users);
                });
            } catch (e) {
                console.error(e);
            }
        },
        async [USERS_ENABLE]({ commit }: actionsParams, userId: string): Promise<void> {
            return FondazioneApi.enableUser(userId);
        },
        async [USERS_DISABLE]({ commit }: actionsParams, userId: string): Promise<void> {
            return FondazioneApi.disableUser(userId);
        },
        async [USERS_DELETE]({ commit }: actionsParams, userId: string): Promise<void> {
            return FondazioneApi.deleteUser(userId);
        },
    },
};
