













import { FIREBASE_LOGIN } from '@/store/modules/fireauth';

export default {
    name: 'Login',
    data(this: any) {
        return {
            show1: false,
            valid: false,
            loading: false,
            rules: {
                required: (value: any) => !!value || 'Campo obbligatorio.',
                min: (v: any) => (v && v.length >= 8) || 'Mininimo 8 caratteri',
            },
            user: '',
            password: '',
        };
    },
    methods: {
        async login(this: any): Promise<void> {
            this.loading = true;
            this.$refs.loginForm.validate();
            if (this.valid) {
                await this.$store.dispatch(FIREBASE_LOGIN, { email: this.user, password: this.password });
            }
            this.loading = false;
        },
    },
};
