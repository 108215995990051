












































import Vue from 'vue';
import { ALBUM_ADD, ALBUM_GET, ALBUM_UPDATE } from '@/store/modules/album';
import { AlbumInt } from '@/api/album/types';

export default Vue.extend({
    name: 'EditAlbum',
    data(this: any) {
        return {
            externalId: '',
            id: '',
            title: '',
            content: '',
            authorCopy: '',
            termini: '',
            luogo: '',
            dataInf: '',
            dataSup: '',
            note: '',
            valid: '',
            loading: false,
            rules: {
                required: (value: any) => !!value || 'Campo obbligatorio.',
                min: (min: number) => (v: any) => (v && v.length >= min) || `Mininimo ${min} caratteri`,
            },
            isOpen: false,
            editing: false,
        };
    },
    computed: {
        fondi(this: any) {
            return this.$store.getters.fondi;
        },
    },
    methods: {
        async update(this: any) {
            try {
                this.loading = true;
                if (this.editing) {
                    await this.$store.dispatch(ALBUM_UPDATE, {
                        id: this.id,
                        externalId: this.externalId,
                        title: this.title,
                        content: this.content,
                        authorCopy: this.authorCopy,
                        termini: this.termini,
                        luogo: this.luogo,
                        dataInf: this.dataInf,
                        dataSup: this.dataSup,
                        note: this.note,
                    });
                } else {
                    await this.$store.dispatch(ALBUM_ADD, {
                        externalId: this.externalId,
                        title: this.title,
                        content: this.content,
                        authorCopy: this.authorCopy,
                        termini: this.termini,
                        luogo: this.luogo,
                        dataInf: this.dataInf,
                        dataSup: this.dataSup,
                        note: this.note,
                    });
                }
                await this.$store.dispatch(ALBUM_GET);
                this.loading = false;
                this.isOpen = false;
            } catch (e) {
                // well...
                console.log(e);
                this.loading = false;
            }
        },
        open(this: any, album: AlbumInt) {
            if (album) {
                this.externalId = album.externalId;
                this.id = album.id;
                this.title = album.title;
                this.content = album.content;
                this.authorCopy = album.authorCopy;
                this.termini = album.termini;
                this.luogo = album.luogo;
                this.dataInf = album.dataInf;
                this.dataSup = album.dataSup;
                this.note = album.note;
                this.editing = true;
            } else {
                this.id = '';
                this.externalId = '';
                this.title = '';
                this.content = '';
                this.authorCopy = '';
                this.termini = '';
                this.luogo = '';
                this.dataInf = '';
                this.dataSup = '';
                this.note = '';
            }
            this.isOpen = true;
        },
    },
});
