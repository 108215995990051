export const GENERIC_STATE_LOADING = 'GENERIC_LOADING';
export const GENERIC_STATE_LINE_LOADING = 'GENERIC_STATE_LINE_LOADING';

interface GenericState {
    isLoading: boolean;
    isLineLoading: boolean;
}

export const loadingWrapper = async <T>(commit: (action: string, payload: unknown) => unknown, callback: () => Promise<T>): Promise<T> => {
    try {
        await commit(GENERIC_STATE_LINE_LOADING, true);
        const res = await callback();
        await commit(GENERIC_STATE_LINE_LOADING, false);
        return res;
    } catch (e) {
        commit(GENERIC_STATE_LINE_LOADING, false);
        throw e;
    }
};

export default {
    state: {
        isLoading: false,
        isLineLoading: false,
    },
    mutations: {
        [GENERIC_STATE_LOADING](state: GenericState, isLoading: boolean): void {
            state.isLoading = isLoading;
        },
        [GENERIC_STATE_LINE_LOADING](state: GenericState, isLoading: boolean): void {
            state.isLineLoading = isLoading;
        },
    },

    getters: {
        isLoading: (state: GenericState): boolean => state.isLoading,
        isLineLoading: (state: GenericState): boolean => state.isLineLoading,
    },
    actions: {
    },
};
