

























import Vue from 'vue';

export default Vue.extend({
    name: 'FondazioneFooter',
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
});
