




















import { FIREBASE_REGISTER } from '@/store/modules/fireauth';

export default {
    name: 'Login',
    data(this: any) {
        return {
            show1: false,
            valid: false,
            loading: false,
            rules: {
                required: (value: any) => !!value || 'Campo obbligatorio.',
                min: (min: number) => (v: any) => (v && v.length >= min) || `Mininimo ${min} caratteri`,
            },
            user: '',
            name: '',
            phone: '',
            affiliation: '',
            password: '',
        };
    },
    methods: {
        async register(this: any): Promise<void> {
            this.loading = true;
            this.$refs.loginForm.validate();
            if (this.valid) {
                await this.$store.dispatch(FIREBASE_REGISTER, {
                    email: this.user, password: this.password, name: this.name, phone: this.phone, affiliation: this.affiliation,
                });
            }
            this.loading = false;
        },
    },
};
