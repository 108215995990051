
























import Table from './components/table.vue';
import { AlbumInt } from '@/api/album/types';
import { LUCIDI_GET, LUCIDI_GET_CAT } from '@/store/modules/lucidi';

export default {
    name: 'Photo',
    components: {
        Table,
    },
    data(this: any) {
        return {
            category: '0',
            subcategory: '0',
            categories: [],
        };
    },
    async mounted(this: any): Promise<void> {
        this.$refs.table.setLoading(true);
        this.category = this.$route.params.category;
        this.subcategory = this.$route.params.subcategory;
        await this.$store.dispatch(LUCIDI_GET, { category: this.category, subcategory: this.subcategory });
        this.categories = await this.$store.dispatch(LUCIDI_GET_CAT);
        this.$refs.table.setLoading(false);
    },
    computed: {
        isAdmin(this: any): boolean {
            return this.$store.getters.isAdmin;
        },
        lucidi(this:any): AlbumInt[] {
            return this.$store.getters.lucidi;
        },
        totalCount(this:any): number {
            return this.$store.getters.lucidiTotalCount;
        },
        currentCategory(this: any): any {
            const main = this.categories.find((item) => item.id === this.category);
            if (main && Array.isArray(main.childs)) {
                const child = main.childs.find((item) => item.id === this.subcategory);
                return child;
            }

            return '';
        },
    },
};
