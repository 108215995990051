















import Vue from 'vue';
import { FONDI_DELETE, FONDI_GET } from '@/store/modules/fondi';
import FondiEdit from './edit.vue';
import { FondiInt } from '@/api/fondi/types';

export default Vue.extend({
    name: 'UserTable',
    components: {
        FondiEdit,
    },
    data(this: any) {
        return {
            headers: [
                { text: 'Nome', value: 'name' },
                {
                    text: 'Descrizione', value: 'description', sortable: false, width: '70%',
                },
                {
                    text: 'Azioni', value: 'actions', sortable: false, align: 'end', width: '10%',
                },
            ],
            search: '',
            loading: false,
            open: false,
        };
    },
    props: ['items'],
    methods: {
        add(this: any) {
            this.$refs.editor.open();
        },
        setLoading(this: any, loading: boolean) {
            this.loading = loading;
        },
        updateItem(this: any, item: FondiInt) {
            this.$refs.editor.open(item);
        },
        async deleteItem(this: any, id: string) {
            this.loading = true;
            await this.$store.dispatch(FONDI_DELETE, id);
            await this.$store.dispatch(FONDI_GET, id);
            this.loading = false;
        },
    },
});
